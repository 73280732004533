<template>
  <div class="items__inner"
    v-loading="isLoading">
    <div v-if="isEmpty">{{ $t('global.noResults') }}</div>
    <el-row
      v-for="(item, index) in items"
      :key="index"
      type="flex"
      class="items__row"
      justify="space-between"
      align="middle">
      <div class="items__label">
        <span v-if="item.name">{{ item.name }}</span>
      </div>
      <router-link
        class="button button--primary items__button"
        :to="{ name: 'InsightsList', params: { term: item.id, name: item.name } }">
        {{ $t('global.insight') }}
      </router-link>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'MacroAssetsRow',
  props: [
    'items',
    'isEmpty',
    'isLoading',
  ],
};
</script>
