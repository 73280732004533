<template>
  <div class="items__inner items__inner--archive">
    <div v-if="isEmpty">{{ $t('global.noResults') }}</div>
    <el-row
      v-for="(item, index) in items"
      :key="index"
      type="flex"
      class="items__row"
      align="middle">
      <div class="items__label">
        <span v-if="item.label">{{ item.label }}</span>
        <span v-if="item.title && item.title.rendered">{{ item.title.rendered }}</span>
      </div>
      <div class="items__date">
        <div class="items__date-heading">{{ $t('global.from') }} - {{ $t('global.to') }}</div>
        <div class="items__date-content">
          <span>{{ item.from }}</span>
          <span>{{ $t('global.to') }}</span>
          <span>{{ item.to }}</span>
        </div>
      </div>
      <div class="items__button-wrapper">
        <a
          class="button button--primary items__button"
          target="_blank"
          :href="item.file">
          {{ $t('global.download') }}
        </a>
      </div>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'ArchiveFileRow',
  props: [
    'items',
    'isEmpty',
  ],
};
</script>
