<template>
  <div>
    <Tabs :tabs="subsections" :item="item"></Tabs>
    <div class="items">
      <div class="items__heading">{{ $t('investmentAdvisory.files') }}</div>
      <ArchiveFileRow
        v-if="$route.params.subsection === 'archive'"
        :items="items"
        :isEmpty="isEmpty">
      </ArchiveFileRow>
      <MacroAssetsRow
        v-else-if="$route.params.subsection === 'documentation'
          && item.acf.is_insight_list === true"
        :items="macroAssets"
        :isEmpty="isEmptyMacroAssets"
        :isLoading="isLoading">
      </MacroAssetsRow>
      <DownloadFileRow
        v-else
        :items="items"
        :isEmpty="isEmpty">
      </DownloadFileRow>
    </div>
  </div>
</template>

<script>
import investmentSubsections from '@/config/investmentSubsections';
import { mapGetters } from 'vuex';
import Tabs from '@/components/Tabs.vue';
import DownloadFileRow from '@/components/DownloadFileRow.vue';
import ArchiveFileRow from '@/components/ArchiveFileRow.vue';
import MacroAssetsRow from '@/components/MacroAssetsRow.vue';
import { investmentAdvisoryApi } from '@/api';

export default {
  name: 'InvestmentAdvisoryTab',
  props: ['item'],
  data() {
    return {
      subsections: investmentSubsections,
      items: [],
      macroAssets: [],
      isLoading: false,
      perPage: 100,
    };
  },
  components: {
    Tabs,
    DownloadFileRow,
    ArchiveFileRow,
    MacroAssetsRow,
  },
  created() {
    this.setItems(this.$route.params.subsection);
    this.setMacroAssets();
  },
  beforeRouteUpdate(to, from, next) {
    this.setItems(to.params.subsection);
    next();
  },
  computed: {
    ...mapGetters('options', ['currentOptions']),
    isEmpty() {
      return (this.items.length === 0);
    },
    isEmptyMacroAssets() {
      return (this.macroAssets.length === 0);
    },
  },
  methods: {
    setItems(subsection) {
      this.items = this.item.acf[subsection] || [];
    },
    async setMacroAssets() {
      if (this.item['macro-asset'] && this.item['macro-asset'].length > 0) {
        try {
          this.isLoading = true;
          const params = {
            per_page: this.perPage,
          };
          const response = await investmentAdvisoryApi.getMacroAssets(params);
          this.isLoading = false;
          this.macroAssets = response.data.filter(
            (item) => this.item['macro-asset'].includes(item.id),
          );
        } catch (error) {
          this.isLoading = false;
          if (error && error.response) {
            this.$notify({
              type: 'error',
              dangerouslyUseHTMLString: true,
              message: error.response.data.message,
            });
          }
          throw error;
        }
      }
    },
  },
};
</script>
