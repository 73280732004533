import i18n from '@/i18n';

const investmentSubsections = [
  {
    label: i18n.t('investmentAdvisory.documentation'),
    to: { name: 'InvestmentAdvisoryTab', params: { subsection: 'documentation' } },
  },
  {
    label: i18n.t('investmentAdvisory.archive'),
    to: { name: 'InvestmentAdvisoryTab', params: { subsection: 'archive' } },
  },
  {
    label: i18n.t('investmentAdvisory.company'),
    to: { name: 'InvestmentAdvisoryTab', params: { subsection: 'company' } },
  },
];

export default investmentSubsections;
